import { useEffect, useMemo, useState } from "react";
import styled from "./styled.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "components/common/select/Select";
import {
  ComposedChart,
  CartesianGrid,
  Label,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  Text as RechartText,
  ResponsiveContainer,
  ReferenceArea,
} from "recharts";
import { default as TooltipMUI } from "@mui/material/Tooltip";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import CropCreateMessages from "pages/CropsCreate/CropCreateMessages";
import chartToCsv from "utils/chartToCsv";
import chartToPng from "utils/chartToPng";
import moment from "moment";
import WaterBalanceResultBodyMessages from "components/WaterBalanceResultBody/WaterBalanceResultBodyMessages";
import WaterBalanceResultMenuMessages from "components/WaterBalanceResultMenu/WaterBalanceResultMenuMessages";
import { useParams } from "react-router-dom";
import { getGrasslandGraph, getGrasslandTable } from "store/analisys/api";

class GrasslandYieldContentProps {
  result: any;
}

interface tableValue {
  zt_harvestable: number;
  under_panel_harvestable: number;
  between_panel_harvestable: number;
  agri_pv_harvestable: number;
  zt_harvestable_ha: number;
  under_panel_harvestable_ha: number;
  between_panel_harvestable_ha: number;
  agri_pv_harvestable_ha: number;
}

function getEquidistantDates(crop: any, n: number) {
  const startDateList = crop.periods.map(
    (period: any) => new Date(period.start_date)
  );
  const endDateList = crop.periods.map(
    (period: any) => new Date(period.end_date)
  );
  const startDate = new Date(Math.min(...startDateList));
  const endDate = new Date(Math.max(...endDateList));
  const start = new Date(startDate);
  const end = new Date(endDate);
  const totalDiff = end.getTime() - start.getTime();
  const interval = totalDiff / (n + 1);
  const dates = [];
  for (let i = 1; i <= n; i++) {
    const date = new Date(start.getTime() + interval * i);
    dates.push(moment(date).format("YYYY-MM-DD"));
  }

  return dates;
}

const GrasslandYieldContent = ({ result }: GrasslandYieldContentProps) => {
  const intl = useIntl();
  const [inactiveKeysPeriod, setInactiveKeyPeriod] = useState(Array<string>());
  const [graphValues, setgraphValues] = useState(Array());
  const [tableValues, setTableValues] = useState<tableValue>();
  const {
    client_id,
    project_id,
    water_balance_id,
    id,
    simulation_water_balance_id,
  } = useParams();

  useEffect(() => {
    getGrasslandGraph({
      clientId: client_id,
      projectId: project_id,
      simulationId: water_balance_id,
      id: id || simulation_water_balance_id,
    }).then((res: any) => setgraphValues(res.data));
    getGrasslandTable({
      clientId: client_id,
      projectId: project_id,
      simulationId: water_balance_id,
      id: id || simulation_water_balance_id,
    }).then((res: any) => setTableValues(res.data));
  }, [client_id, project_id, water_balance_id, id, result]);

  const CustomizedTick = (props: any) => {
    const { x, y, stroke, payload, offset } = props;
    return (
      <g transform={`translate(${x},${y - 10})`}>
        <RechartText
          x={0}
          y={0}
          dy={16}
          angle={-65}
          width={100}
          fontSize={10}
          textAnchor="end"
          verticalAnchor="middle"
        >
          {moment(payload.value).format("DD MMM")}
        </RechartText>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const period = result.simulation.crop_for_water_balance.periods.find(
        (period: any) =>
          new Date(period.start_date) <= new Date(label) &&
          new Date(label) <= new Date(period.end_date)
      );

      return (
        <div className={styled.customTooltip}>
          <p className={styled.label}>{moment(label).format("DD MMM")}</p>
          <p className={styled.label}>
            {intl.formatMessage(CropCreateMessages.stageName)}:{" "}
            {period ? period.name : "Out of periods."}
          </p>
          <div>
            {payload.map((pld: any) => (
              <div className={styled.flex} id={pld.name}>
                <div>{pld.name} :</div>
                <div style={{ color: pld.stroke }}>
                  {pld.value} {pld.unit}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  const GrasslandTypeOptions = [
    { label: "Harvestable production", value: "HARVESTABLE" },
    { label: "Growth dynamic", value: "DYNAMIC" },
  ];

  const HarvestableUnite = [
    {
      label: "%",
      value: "%",
    },
    {
      label: "tMS/ha",
      value: "tMS/ha",
    },
  ];

  const [selectedGrassTypeOptions, setSelectedGrassTypeOptions] = useState<
    "HARVESTABLE" | "DYNAMIC"
  >("HARVESTABLE");

  const [selectedGrasslandUnite, setSelectedGrasslandUnite] = useState<
    "%" | "tMS/ha"
  >("%");

  return (
    <div className={styled.list}>
      <div className={styled.optionContainer}>
        <div className={styled.doubleUniteSelector}>
          <div className={styled.doubleUniteSelectorItem}>
            <Select
              label="Chart Type"
              labelPosition="left"
              options={GrasslandTypeOptions}
              value={GrasslandTypeOptions.find(
                (item) => item.value == selectedGrassTypeOptions
              )}
              onChange={(e: any) => setSelectedGrassTypeOptions(e.value)}
              isSearchable={false}
              menuHeight={400}
            />
          </div>
          {selectedGrassTypeOptions === "HARVESTABLE" && (
            <div className={styled.doubleUniteSelectorItem}>
              <Select
                labelPosition="left"
                label="Unit"
                options={HarvestableUnite}
                value={HarvestableUnite.find(
                  (item) => item.value == selectedGrasslandUnite
                )}
                onChange={(e: any) => {
                  setSelectedGrasslandUnite(e.value);
                }}
                isSearchable={false}
                menuHeight={400}
              />
            </div>
          )}
        </div>
      </div>
      {graphValues && (
        <div className={styled.row}>
          {selectedGrassTypeOptions === "DYNAMIC" && (
            <div className={styled.chartContainer}>
              <div style={{ width: "100%", height: "100%" }}>
                <div className={styled.optionContainer}>
                  <div className={styled.chartTitle}>{"Growth dynamic"}</div>
                  <div className={styled.downloadContainer}>
                    <TooltipMUI
                      title={intl.formatMessage(
                        WaterBalanceResultBodyMessages.hintChartToPng
                      )}
                    >
                      <div
                        className={styled.chartToPngButton}
                        onClick={chartToPng("GrasslandYeildChart")}
                      >
                        <Icon
                          name={ICON_NAMES_ENUM.download_image}
                          className={styled.file__icon}
                        />
                      </div>
                    </TooltipMUI>
                    <TooltipMUI
                      title={intl.formatMessage(
                        WaterBalanceResultBodyMessages.hintChartToCsv
                      )}
                    >
                      <div
                        className={styled.chartToPngButton}
                        onClick={chartToCsv(
                          graphValues, // daily_grassland_points.normal,
                          `${result.simulation.name} Grassland Yeild (kgMS/ha/j)`
                        )}
                      >
                        <Icon
                          name={ICON_NAMES_ENUM.download_file}
                          className={styled.file__icon}
                        />
                      </div>
                    </TooltipMUI>
                  </div>
                </div>
                <div id={"GrasslandYeildChart"}>
                  <ResponsiveContainer width="100%" height={300}>
                    <ComposedChart
                      width={500}
                      height={500}
                      data={graphValues}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        height={75}
                        dataKey="date"
                        tick={<CustomizedTick />}
                        interval={0}
                        ticks={getEquidistantDates(
                          result.simulation.crop_for_water_balance,
                          11
                        )}
                      />
                      <YAxis>
                        <Label
                          fontSize={12}
                          style={{ textAnchor: "middle" }}
                          position={"insideLeft"}
                          angle={270}
                          value={"Growth dynamic (kgMS/ha/j)"}
                        />
                      </YAxis>
                      <Tooltip content={CustomTooltip} />
                      <Legend
                        onClick={(o) => {
                          if (
                            !inactiveKeysPeriod.find(
                              (inactiveKey) => inactiveKey == o.dataKey
                            )
                          )
                            setInactiveKeyPeriod([
                              ...inactiveKeysPeriod,
                              o.dataKey,
                            ]);
                          else {
                            var temp = inactiveKeysPeriod;
                            temp.splice(
                              inactiveKeysPeriod.findIndex(
                                (inactiveKey) => inactiveKey == o.dataKey
                              ),
                              1
                            );
                            setInactiveKeyPeriod([...temp]);
                          }
                        }}
                      />
                      {result.simulation.crop_for_water_balance.periods.map(
                        (period: any, index: number) => (
                          <ReferenceArea
                            id={period.id}
                            x1={period.start_date}
                            x2={period.end_date}
                            fill={index % 2 ? "#333" : "#888"}
                            fillOpacity={0.1}
                          />
                        )
                      )}
                      <Line
                        dot={<></>}
                        type="monotone"
                        dataKey={"zt_dynamic"}
                        name="Control Zone"
                        stroke={"#AA6666"}
                        fill="url(#splitColor)"
                        unit={"kgMS/ha/j"}
                        hide={
                          inactiveKeysPeriod.find(
                            (inactiveKey) => inactiveKey == "zt"
                          )
                            ? true
                            : false
                        }
                      />
                      <Line
                        dot={<></>}
                        type="monotone"
                        dataKey={"under_panel_dynamic"}
                        hide={
                          inactiveKeysPeriod.find(
                            (inactiveKey) =>
                              inactiveKey == "under_panel_dynamic"
                          )
                            ? true
                            : false
                        }
                        name="Under Panels"
                        stroke={"#66AA66"}
                        fill="url(#splitColor)"
                        unit={"kgMS/ha/j"}
                      />
                      <Line
                        dot={<></>}
                        type="monotone"
                        dataKey={"between_panel_dynamic"}
                        hide={
                          inactiveKeysPeriod.find(
                            (inactiveKey) =>
                              inactiveKey == "between_panel_dynamic"
                          )
                            ? true
                            : false
                        }
                        name="Between Panels"
                        stroke={"#6666AA"}
                        fill="url(#splitColor)"
                        unit={"kgMS/ha/j"}
                      />
                      <Line
                        dot={<></>}
                        type="monotone"
                        dataKey={"agri_pv_dynamic"}
                        hide={
                          inactiveKeysPeriod.find(
                            (inactiveKey) => inactiveKey == "agri_pv_dynamic"
                          )
                            ? true
                            : false
                        }
                        name="AgriPV Zone"
                        stroke={"#444444"}
                        fill="url(#splitColor)"
                        unit={"kgMS/ha/j"}
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          )}
          {selectedGrassTypeOptions === "HARVESTABLE" && (
            <div className={styled.chartContainer}>
              <div style={{ width: "100%", height: "100%" }}>
                <div className={styled.optionContainer}>
                  <div className={styled.chartTitle}>
                    {"Harvestable production"}
                  </div>
                  <div className={styled.downloadContainer}>
                    <TooltipMUI
                      title={intl.formatMessage(
                        WaterBalanceResultBodyMessages.hintChartToPng
                      )}
                    >
                      <div
                        className={styled.chartToPngButton}
                        onClick={chartToPng("HarvestableProductionChart")}
                      >
                        <Icon
                          name={ICON_NAMES_ENUM.download_image}
                          className={styled.file__icon}
                        />
                      </div>
                    </TooltipMUI>
                    <TooltipMUI
                      title={intl.formatMessage(
                        WaterBalanceResultBodyMessages.hintChartToCsv
                      )}
                    >
                      <div
                        className={styled.chartToPngButton}
                        onClick={chartToCsv(
                          // daily_grassland_points.cumulated,
                          graphValues,
                          `${result.simulation.name} Harvestable Production (kgMS/ha)`
                        )}
                      >
                        <Icon
                          name={ICON_NAMES_ENUM.download_file}
                          className={styled.file__icon}
                        />
                      </div>
                    </TooltipMUI>
                  </div>
                </div>
                <div id={"HarvestableProductionChart"}>
                  <ResponsiveContainer width="100%" height={300}>
                    <ComposedChart
                      width={500}
                      height={500}
                      data={graphValues}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        height={75}
                        dataKey="date"
                        tick={<CustomizedTick />}
                        interval={0}
                        ticks={getEquidistantDates(
                          result.simulation.crop_for_water_balance,
                          11
                        )}
                      />
                      <YAxis>
                        <Label
                          fontSize={12}
                          style={{ textAnchor: "middle" }}
                          position={"insideLeft"}
                          angle={270}
                          value={`Harverstable Production (${selectedGrasslandUnite})`}
                        />
                      </YAxis>
                      <Tooltip content={CustomTooltip} />
                      <Legend
                        onClick={(o) => {
                          if (
                            !inactiveKeysPeriod.find(
                              (inactiveKey) => inactiveKey == o.dataKey
                            )
                          )
                            setInactiveKeyPeriod([
                              ...inactiveKeysPeriod,
                              o.dataKey,
                            ]);
                          else {
                            var temp = inactiveKeysPeriod;
                            temp.splice(
                              inactiveKeysPeriod.findIndex(
                                (inactiveKey) => inactiveKey == o.dataKey
                              ),
                              1
                            );
                            setInactiveKeyPeriod([...temp]);
                          }
                        }}
                      />
                      {result.simulation.crop_for_water_balance.periods.map(
                        (period: any, index: number) => (
                          <ReferenceArea
                            id={period.id}
                            x1={period.start_date}
                            x2={period.end_date}
                            fill={index % 2 ? "#333" : "#888"}
                            fillOpacity={0.1}
                          />
                        )
                      )}
                      <Line
                        dot={<></>}
                        type="monotone"
                        dataKey={
                          selectedGrasslandUnite === "%"
                            ? "zt_harvestable"
                            : "zt_harvestable_ha"
                        }
                        hide={
                          inactiveKeysPeriod.find(
                            (inactiveKey) => inactiveKey == "zt"
                          )
                            ? true
                            : false
                        }
                        name="Control Zone"
                        stroke={"#AA6666"}
                        fill="url(#splitColor)"
                        unit={selectedGrasslandUnite === "%" ? "%" : "tMS/ha"}
                      />

                      <Line
                        dot={<></>}
                        type="monotone"
                        dataKey={
                          selectedGrasslandUnite === "%"
                            ? "under_panel_harvestable"
                            : "under_panel_harvestable_ha"
                        }
                        hide={
                          inactiveKeysPeriod.find(
                            (inactiveKey) =>
                              inactiveKey == "under_panel_harvestable"
                          )
                            ? true
                            : false
                        }
                        name="Under Panels"
                        stroke={"#66AA66"}
                        fill="url(#splitColor)"
                        unit={selectedGrasslandUnite === "%" ? "%" : "tMS/ha"}
                      />
                      <Line
                        dot={<></>}
                        type="monotone"
                        dataKey={
                          selectedGrasslandUnite === "%"
                            ? "between_panel_harvestable"
                            : "between_panel_harvestable_ha"
                        }
                        hide={
                          inactiveKeysPeriod.find(
                            (inactiveKey) =>
                              inactiveKey == "between_panel_harvestable"
                          )
                            ? true
                            : false
                        }
                        name="Between Panels"
                        stroke={"#6666AA"}
                        fill="url(#splitColor)"
                        unit={selectedGrasslandUnite === "%" ? "%" : "tMS/ha"}
                      />
                      <Line
                        dot={<></>}
                        type="monotone"
                        dataKey={
                          selectedGrasslandUnite === "%"
                            ? "agri_pv_harvestable"
                            : "agri_pv_harvestable_ha"
                        }
                        hide={
                          inactiveKeysPeriod.find(
                            (inactiveKey) =>
                              inactiveKey == "agri_pv_harvestable"
                          )
                            ? true
                            : false
                        }
                        name="AgriPV Zone"
                        stroke={"#444444"}
                        fill="url(#splitColor)"
                        unit={selectedGrasslandUnite === "%" ? "%" : "tMS/ha"}
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {tableValues && (
        <div className={styled.tableContainer}>
          <div className={styled.downloadContainer}>
            <div className={styled.chartTitle}>
              <FormattedMessage
                {...WaterBalanceResultMenuMessages.tableTitle}
              />
            </div>
            <TooltipMUI
              title={intl.formatMessage(
                WaterBalanceResultBodyMessages.hintTableToPng
              )}
            >
              <div
                className={styled.chartToPngButton}
                onClick={chartToPng("GrasslandYieldTable")}
              >
                <Icon
                  name={ICON_NAMES_ENUM.download_image}
                  className={styled.file__icon}
                />
              </div>
            </TooltipMUI>
            <TooltipMUI
              title={intl.formatMessage(
                WaterBalanceResultBodyMessages.hintTableToCsv
              )}
            >
              <div
                className={styled.chartToPngButton}
                onClick={chartToCsv(
                  tableValues,
                  `${result.simulation.name} table data`
                )}
              >
                <Icon
                  name={ICON_NAMES_ENUM.download_file}
                  className={styled.file__icon}
                />
              </div>
            </TooltipMUI>
          </div>
          <div className={`${styled.flex} ${styled.tableContainerOverflow}`}>
            <table className={styled.dataTable} id="GrasslandYieldTable">
              <thead>
                <tr>
                  <th colSpan={4}>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.tableTitleRow}
                      values={{ unit: selectedGrasslandUnite }}
                    />
                  </th>
                </tr>
                <tr>
                  <th>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.controlZoneSubTitle}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.underPanelsSubTitle}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.betweenPanelsZoneSubTitle}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.agriPVZoneSubTitle}
                    />
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td className={styled.specialCaseTd}>
                    <span>
                      {selectedGrasslandUnite === "%"
                        ? tableValues.zt_harvestable
                        : tableValues.zt_harvestable_ha}
                    </span>
                  </td>
                  <td>
                    <span>
                      {selectedGrasslandUnite === "%"
                        ? tableValues.under_panel_harvestable
                        : tableValues.under_panel_harvestable_ha}
                    </span>
                  </td>
                  <td>
                    <span>
                      {selectedGrasslandUnite === "%"
                        ? tableValues.between_panel_harvestable
                        : tableValues.between_panel_harvestable_ha}
                    </span>
                  </td>
                  <td>
                    <span>
                      {selectedGrasslandUnite === "%"
                        ? tableValues.agri_pv_harvestable
                        : tableValues.agri_pv_harvestable_ha}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default GrasslandYieldContent;
