import { useEffect, useMemo, useState } from "react";
import styled from "./styled.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "components/common/select/Select";
import {
  Bar,
  ComposedChart,
  CartesianGrid,
  Label,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  Text as RechartText,
  ResponsiveContainer,
  ReferenceArea,
} from "recharts";
import { default as TooltipMUI } from "@mui/material/Tooltip";
import html2canvas from "html2canvas";
import { errorNotifications } from "utils/errorNotifications";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import SimulationResultBodyMessages from "components/SimulationResultBody/SimulationResultBodyMessages";
import CropCreateMessages from "pages/CropsCreate/CropCreateMessages";
import chartToCsv from "utils/chartToCsv";
import chartToPng from "utils/chartToPng";
import WaterBalanceResultBodyMessages from "components/WaterBalanceResultBody/WaterBalanceResultBodyMessages";
import moment from "moment";
import WaterBalanceResultMenuMessages from "./WaterBalanceResultMenuMessages";
import { useParams } from "react-router-dom";
import {
  getAvailableWaterStockTable,
  getWaterStressTable,
} from "store/analisys/api";

const RUUnites = ["MM", "FRACTION"];

class IrradianceContentProps {
  result: any;
  unite: "FRACTION" | "MM" = "FRACTION";
}

const AvailableWaterStockContent = ({
  result,
  unite,
}: IrradianceContentProps) => {
  const intl = useIntl();

  const [availableWaterStockData, setAvailableWaterStockData] = useState<any[]>(
    []
  );
  const [tableValues, setTableValues] = useState<any[]>([]);

  const { client_id, project_id, water_balance_id, id, simulation_water_balance_id } = useParams();

  useEffect(() => {
    getAvailableWaterStockTable({
      clientId: client_id,
      projectId: project_id,
      simulationId: water_balance_id,
      id: id || simulation_water_balance_id,
    }).then((res) => setAvailableWaterStockData(res.data));
    getWaterStressTable({
      clientId: client_id,
      projectId: project_id,
      simulationId: water_balance_id,
      id: id || simulation_water_balance_id,
    }).then((res) => setTableValues(res.data));
  }, [client_id, project_id, water_balance_id, id]);

  const [inactiveKeysDailyRU, setInactiveKeyDailyRU] = useState(["under_panel", "between_panel", "stress_indicator_under_panels", "stress_indicator_between_panels"]);

  const uniteDailyRUOptions = useMemo(() => {
    return RUUnites.map((item) => ({
      value: item,
      label: item,
    }));
  }, [RUUnites]);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const period = result.simulation.crop_for_water_balance.periods.find(
        (period: any) =>
          new Date(period.start_date) <= new Date(label) &&
          new Date(label) <= new Date(period.end_date)
      );

      return (
        <div className={styled.customTooltip}>
          <p className={styled.label}>{moment(label).format("DD MMM")}</p>
          <p className={styled.label}>
            {intl.formatMessage(CropCreateMessages.stageName)}:{" "}
            {period ? period.name : "Out of periods."}
          </p>
          <div>
            {payload.map((pld: any) => (
              <div className={styled.flex} id={pld.name}>
                <div>{pld.name} :</div>
                <div style={{ color: pld.stroke }}>
                  {pld.value} {pld.unit}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  if (!availableWaterStockData.length || !tableValues.length)
    return <div style={{ height: "100vh" }} />;

  const CustomizedTick = (props: any) => {
    const { x, y, stroke, payload, offset } = props;
    return (
      <g transform={`translate(${x},${y - 10})`}>
        <RechartText
          x={0}
          y={0}
          dy={16}
          angle={-65}
          width={100}
          fontSize={10}
          textAnchor="end"
          verticalAnchor="middle"
        >
          {moment(payload.value).format("DD MMM")}
        </RechartText>
      </g>
    );
  };

  return (
    <div className={styled.list}>
      <div className={styled.chartContainer}>
        <div style={{ width: "100%", height: "100%" }}>
          <div className={styled.optionContainer}>
            <div></div>
            <div className={styled.downloadContainer}>
              <TooltipMUI
                title={intl.formatMessage(
                  WaterBalanceResultBodyMessages.hintChartToPng
                )}
              >
                <div
                  className={styled.chartToPngButton}
                  onClick={chartToPng("AvailableWaterStock")}
                >
                  <Icon
                    name={ICON_NAMES_ENUM.download_image}
                    className={styled.file__icon}
                  />
                </div>
              </TooltipMUI>
              <TooltipMUI
                title={intl.formatMessage(
                  WaterBalanceResultBodyMessages.hintChartToCsv
                )}
              >
                <div
                  className={styled.chartToPngButton}
                  onClick={chartToCsv(
                    availableWaterStockData,
                    `${result.simulation.name} Available Water Stock (${unite})`
                  )}
                >
                  <Icon
                    name={ICON_NAMES_ENUM.download_file}
                    className={styled.file__icon}
                  />
                </div>
              </TooltipMUI>
            </div>
          </div>

          <div id={"AvailableWaterStock"}>
            <div className={styled.chartTitle}>{"Available Water Stock"}</div>
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart
                width={500}
                height={500}
                data={availableWaterStockData.map((values: any) => ({
                  ...values,
                  RFU: unite == "FRACTION" ? values.fraction_RFU : values.RFU,
                  zt:
                    unite == "FRACTION"
                      ? values.fraction_rfu_ref
                      : values.rfu_ref,
                  under_panel:
                    unite == "FRACTION"
                      ? values.fraction_rfu_under_panel
                      : values.rfu_under_panel,
                  between_panel:
                    unite == "FRACTION"
                      ? values.fraction_rfu_between_panel
                      : values.rfu_between_panel,
                  agri_pv:
                    unite == "FRACTION"
                      ? values.fraction_rfu_agri_pv
                      : values.rfu_agri_pv,
                }))}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  height={75}
                  dataKey="date"
                  interval={0}
                  tick={<CustomizedTick />}
                  ticks={[
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-01-01`,
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-02-01`,
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-03-01`,
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-04-01`,
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-05-01`,
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-06-01`,
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-07-01`,
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-08-01`,
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-09-01`,
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-10-01`,
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-11-01`,
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-12-01`,
                  ]}
                />
                <YAxis type="number" domain={unite == "FRACTION" ? [0, 200] : [0, Math.round(Math.max(...availableWaterStockData.map((value: any) => value.rfu_ref))) * 2]} yAxisId={"waterStock"} orientation="left" tickFormatter={(value) => (value >= 0 && value <= (unite == "FRACTION" ? 100 : Math.round(Math.max(...availableWaterStockData.map((value: any) => value.rfu_ref)))) ? value : '')} ticks={unite == "FRACTION" ? [0, 50, 100, 150, 200] : [0, Math.round(Math.max(...availableWaterStockData.map((value: any) => value.rfu_ref))) / 2, Math.round(Math.max(...availableWaterStockData.map((value: any) => value.rfu_ref))), Math.round(Math.max(...availableWaterStockData.map((value: any) => value.rfu_ref))) * 2]}>
                  <Label
                    fontSize={12}
                    style={{
                      textAnchor: "middle",
                    }}
                    position={"insideLeft"}
                    angle={270}
                    value={unite == "FRACTION" ? "Water storage filling (%)" : "Water storage filling (mm)"}
                  />
                </YAxis>
                <YAxis type="number" domain={[-1, 1]} yAxisId={"stressIndicator"} orientation="right" tickFormatter={(value) => value >= 0 ? value : ""}>
                  <Label
                    fontSize={12}
                    style={{
                      textAnchor: "middle",
                    }}
                    position={"insideRight"}
                    angle={270}
                    value={"Stress Indicator"}
                  />
                </YAxis>
                {result.simulation.crop_for_water_balance.periods.map(
                  (period: any, index: number) => (
                    <ReferenceArea
                      id={`ReferenceArea ${period.id}`}
                      x1={period.start_date}
                      x2={period.end_date}
                      fill={index % 2 ? "#333" : "#888"}
                      fillOpacity={0.1}
                    />
                  )
                )}
                <Tooltip content={CustomTooltip} />
                <Legend
                  onClick={(o) => {
                    if (
                      !inactiveKeysDailyRU.find(
                        (inactiveKey) => inactiveKey == o.dataKey
                      )
                    )
                      setInactiveKeyDailyRU([
                        ...inactiveKeysDailyRU,
                        o.dataKey,
                      ]);
                    else {
                      var temp = inactiveKeysDailyRU;
                      temp.splice(
                        inactiveKeysDailyRU.findIndex(
                          (inactiveKey) => inactiveKey == o.dataKey
                        ),
                        1
                      );
                      setInactiveKeyDailyRU([...temp]);
                    }
                  }}
                />
                <Line
                  yAxisId={"waterStock"}
                  strokeDasharray="3 1"
                  dot={<></>}
                  type="monotone"
                  dataKey={"zt"}
                  name="Control Zone"
                  fill={"#AA6666"}
                  stroke={"#AA6666"}
                  unit={unite == "MM" ? "mm" : "%"}
                  hide={
                    inactiveKeysDailyRU.find(
                      (inactiveKey) => inactiveKey == "zt"
                    )
                      ? true
                      : false
                  }
                />
                <Line
                  yAxisId={"stressIndicator"}
                  strokeDasharray="1 2"
                  dot={<></>}
                  type="monotone"
                  dataKey={"stress_indicator_ref"}
                  name="Stress Indicator Ref"
                  fill={"#AA6666"}
                  stroke={"#AA6666"}
                  hide={
                    inactiveKeysDailyRU.find(
                      (inactiveKey) => inactiveKey == "stress_indicator_ref"
                    )
                      ? true
                      : false
                  }
                />
                <Line
                  yAxisId={"waterStock"}
                  strokeDasharray="3 1"
                  dot={<></>}
                  type="monotone"
                  dataKey={"agri_pv"}
                  name="AgriPV Zone"
                  fill={"#444444"}
                  stroke={"#444444"}
                  unit={unite == "MM" ? "mm" : "%"}
                  hide={
                    inactiveKeysDailyRU.find(
                      (inactiveKey) => inactiveKey == "agri_pv"
                    )
                      ? true
                      : false
                  }
                />
                <Line
                  yAxisId={"stressIndicator"}
                  strokeDasharray="1 2"
                  dot={<></>}
                  type="monotone"
                  dataKey={"stress_indicator_agripv"}
                  name="Stress Indicator AgriPV"
                  fill={"#444444"}
                  stroke={"#444444"}
                  hide={
                    inactiveKeysDailyRU.find(
                      (inactiveKey) => inactiveKey == "stress_indicator_agripv"
                    )
                      ? true
                      : false
                  }
                />
                <Line
                  yAxisId={"waterStock"}
                  strokeDasharray="3 1"
                  dot={<></>}
                  type="monotone"
                  dataKey={"under_panel"}
                  name="Under Panels"
                  fill={"#66AA66"}
                  stroke={"#66AA66"}
                  unit={unite == "MM" ? "mm" : "%"}
                  hide={
                    inactiveKeysDailyRU.find(
                      (inactiveKey) => inactiveKey == "under_panel"
                    )
                      ? true
                      : false
                  }
                />
                <Line
                  yAxisId={"stressIndicator"}
                  strokeDasharray="1 2"
                  dot={<></>}
                  type="monotone"
                  dataKey={"stress_indicator_under_panels"}
                  name="Stress Indicator Under Panels"
                  fill={"#66AA66"}
                  stroke={"#66AA66"}
                  hide={
                    inactiveKeysDailyRU.find(
                      (inactiveKey) => inactiveKey == "stress_indicator_under_panels"
                    )
                      ? true
                      : false
                  }
                />
                <Line
                  yAxisId={"waterStock"}
                  strokeDasharray="3 1"
                  dot={<></>}
                  type="monotone"
                  dataKey={"between_panel"}
                  name="Between Panels"
                  fill={"#6666AA"}
                  stroke={"#6666AA"}
                  unit={unite == "MM" ? "mm" : "%"}
                  hide={
                    inactiveKeysDailyRU.find(
                      (inactiveKey) => inactiveKey == "between_panel"
                    )
                      ? true
                      : false
                  }
                />
                <Line
                  yAxisId={"stressIndicator"}
                  strokeDasharray="1 2"
                  dot={<></>}
                  type="monotone"
                  dataKey={"stress_indicator_between_panels"}
                  name="Stress Indicator Between Panels"
                  fill={"#6666AA"}
                  stroke={"#6666AA"}
                  hide={
                    inactiveKeysDailyRU.find(
                      (inactiveKey) => inactiveKey == "stress_indicator_between_panels"
                    )
                      ? true
                      : false
                  }
                />

              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div>
        <div className={styled.downloadContainer}>
          <div className={styled.chartTitle}>
            {!result.simulation.water_balance.irrigation ? (
              <FormattedMessage
                {...WaterBalanceResultBodyMessages.waterStressNumber}
              />
            ) : (
              <FormattedMessage
                {...WaterBalanceResultBodyMessages.irrigationValue}
              />
            )}
          </div>
          <TooltipMUI
            title={intl.formatMessage(
              SimulationResultBodyMessages.hintChartToPng
            )}
          >
            <div
              className={styled.chartToPngButton}
              onClick={chartToPng("Table AvailableWaterStock")}
            >
              <Icon
                name={ICON_NAMES_ENUM.download_image}
                className={styled.file__icon}
              />
            </div>
          </TooltipMUI>
          <TooltipMUI
            title={intl.formatMessage(
              WaterBalanceResultBodyMessages.hintChartToCsv
            )}
          >
            <div
              className={styled.chartToPngButton}
              onClick={chartToCsv(
                tableValues,
                `${result.simulation.name} ${!result.simulation.water_balance.irrigation
                  ? intl.formatMessage(
                    WaterBalanceResultBodyMessages.waterStressNumber
                  )
                  : intl.formatMessage(
                    WaterBalanceResultBodyMessages.irrigationValue
                  )
                } `
                // `${result.simulation.name} Available Water Stock (${unite})`
              )}
            >
              <Icon
                name={ICON_NAMES_ENUM.download_file}
                className={styled.file__icon}
              />
            </div>
          </TooltipMUI>
        </div>
        <div id="Table AvailableWaterStock">
          <table className={styled.dataTable}>
            <thead>
              <tr>
                <th rowSpan={2}>
                  <FormattedMessage {...CropCreateMessages.stageName} />
                </th>
                <th colSpan={4}>
                  <FormattedMessage
                    {...WaterBalanceResultBodyMessages.waterStressNumber}
                  />
                </th>
              </tr>
              <tr className={styled.specialCaseTable}>
                <th>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.controlZoneSubTitle}
                    />
                  </div>
                </th>
                <th>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.underPanelsSubTitle}
                    />
                  </div>
                </th>
                <th>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.betweenPanelsZoneSubTitle}
                    />
                  </div>
                </th>
                <th>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.agriPVZoneSubTitle}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableValues != null &&
                tableValues.map((item: any) => (
                  <tr key={`tableIndex${item.name}`}>
                    <td>{item.period.name}</td>
                    <td>{item.zt}</td>
                    <td>{item.under_panel}</td>
                    <td>{item.between_panel}</td>
                    <td>{item.agri_pv}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {result.simulation.water_balance.irrigation && <div>
        <div className={styled.downloadContainer}>
          <div className={styled.chartTitle}>
            <FormattedMessage
              {...WaterBalanceResultBodyMessages.waterNeed}
            />
          </div>
          <TooltipMUI
            title={intl.formatMessage(
              SimulationResultBodyMessages.hintChartToPng
            )}
          >
            <div
              className={styled.chartToPngButton}
              onClick={chartToPng("Table WaterNeed")}
            >
              <Icon
                name={ICON_NAMES_ENUM.download_image}
                className={styled.file__icon}
              />
            </div>
          </TooltipMUI>
          <TooltipMUI
            title={intl.formatMessage(
              WaterBalanceResultBodyMessages.hintChartToCsv
            )}
          >
            <div
              className={styled.chartToPngButton}
              onClick={chartToCsv(
                tableValues,
                `${result.simulation.name} ${intl.formatMessage(WaterBalanceResultBodyMessages.waterNeed)}`
                // `${result.simulation.name} Available Water Stock (${unite})`
              )}
            >
              <Icon
                name={ICON_NAMES_ENUM.download_file}
                className={styled.file__icon}
              />
            </div>
          </TooltipMUI>
        </div>
        <div id="Table WaterNeed">
          <table className={styled.dataTable}>
            <thead>
              <tr>
                <th rowSpan={2}>
                  <FormattedMessage {...CropCreateMessages.stageName} />
                </th>
                <th colSpan={4}>
                  <FormattedMessage
                    {...WaterBalanceResultBodyMessages.waterNeed}
                  />
                </th>
              </tr>
              <tr className={styled.specialCaseTable}>
                <th>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.controlZoneSubTitle}
                    />
                  </div>
                </th>
                <th>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.underPanelsSubTitle}
                    />
                  </div>
                </th>
                <th>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.betweenPanelsZoneSubTitle}
                    />
                  </div>
                </th>
                <th>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.agriPVZoneSubTitle}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableValues != null &&
                tableValues.map((item: any) => (
                  <tr key={`tableIndex${item.name}`}>
                    <td>{item.period.name}</td>
                    <td>{item.water_need_control_zone}</td>
                    <td>{item.water_need_under_panels}</td>
                    <td>{item.water_need_between_panels}</td>
                    <td>{item.water_need_agriPV}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>}
    </div>
  );
};

export default AvailableWaterStockContent;
