import { useEffect, useMemo, useState } from "react";
import styled from "./styled.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "components/common/select/Select";
import {
  Bar,
  ComposedChart,
  CartesianGrid,
  Label,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  Text as RechartText,
  ResponsiveContainer,
} from "recharts";
import { default as TooltipMUI } from "@mui/material/Tooltip";
import html2canvas from "html2canvas";
import { errorNotifications } from "utils/errorNotifications";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import SimulationResultBodyMessages from "components/SimulationResultBody/SimulationResultBodyMessages";
import CustomTooltip from "components/CustomTooltip/CustomTooltip";
import CropCreateMessages from "pages/CropsCreate/CropCreateMessages";
import chartToCsv from "utils/chartToCsv";
import chartToPng from "utils/chartToPng";
import CustomTick from "components/CustomTick/CustomTick";
import SimulationResultMessages from "pages/SimulationResult/SimulationResultMessages";
import { getIrradianceTable } from "store/analisys/api";
import { useParams } from "react-router-dom";
import WaterBalanceResultMenuMessages from "./WaterBalanceResultMenuMessages";
import { Loader } from "components/common/loader/Loader";

const irrUnites = ["Fraction", "Daily PAR"];

class IrradianceContentProps {
  result: any;
}

const IrradianceContent = ({ result }: IrradianceContentProps) => {
  const intl = useIntl();
  const valuePeriodIrrOptions = [
    {
      label: "%",
      value: "FRACTION",
    },
    {
      label: "Cumulated",
      value: "CUMULATED",
    },
    {
      label: "Daily",
      value: "DAILY",
    },
  ];
  const unitePeriodIrrOptions = [
    {
      label: "Irradiance",
      value: "IRR",
    },
    {
      label: "PAR",
      value: "PAR",
    },
  ];
  const [selectedPeriodIrrUnite, setSelectedPeriodIrrUnite] = useState<
    "IRR" | "PAR"
  >("IRR");
  const [selectedPeriodIrrValue, setSelectedPeriodIrrValue] = useState<
    "FRACTION" | "CUMULATED" | "DAILY"
  >("FRACTION");

  const {
    client_id,
    project_id,
    water_balance_id,
    id,
    simulation_water_balance_id,
  } = useParams();
  const [tableValues, setTableValues] = useState([]);

  useEffect(() => {
    getIrradianceTable({
      clientId: client_id,
      projectId: project_id,
      simulationId: water_balance_id,
      id: id || simulation_water_balance_id,
    }).then((res) => setTableValues(res.data));
  }, [client_id, project_id, water_balance_id, id]);

  const good_table_values = () => {
    return tableValues.map((data: any) => {
      let irr_zt = 0;
      let irr_under_panel = 0;
      let irr_between_panel = 0;
      let irr_agri_pv = 0;
      if (selectedPeriodIrrValue == "FRACTION") {
        irr_zt = data.fraction_irr_zt;
        irr_under_panel = data.fraction_irr_under_panel;
        irr_between_panel = data.fraction_irr_between_panel;
        irr_agri_pv = data.fraction_irr_agri_pv;
      } else if (selectedPeriodIrrValue == "CUMULATED") {
        if (selectedPeriodIrrUnite == "IRR") {
          irr_zt = data.cumulated_irr_zt;
          irr_under_panel = data.cumulated_irr_under_panel;
          irr_between_panel = data.cumulated_irr_between_panel;
          irr_agri_pv = data.cumulated_irr_agri_pv;
        } else if (selectedPeriodIrrUnite == "PAR") {
          irr_zt = data.cumulated_par_irr_zt;
          irr_under_panel = data.cumulated_par_irr_under_panel;
          irr_between_panel = data.cumulated_par_irr_between_panel;
          irr_agri_pv = data.cumulated_par_irr_agri_pv;
        }
      } else if (selectedPeriodIrrValue == "DAILY") {
        if (selectedPeriodIrrUnite == "IRR") {
          irr_zt = data.daily_irr_zt;
          irr_under_panel = data.daily_irr_under_panel;
          irr_between_panel = data.daily_irr_between_panel;
          irr_agri_pv = data.daily_irr_agri_pv;
        } else if (selectedPeriodIrrUnite == "PAR") {
          irr_zt = data.daily_par_irr_zt;
          irr_under_panel = data.daily_par_irr_under_panel;
          irr_between_panel = data.daily_par_irr_between_panel;
          irr_agri_pv = data.daily_par_irr_agri_pv;
        }
      }

      return {
        ...data,
        irr_zt: irr_zt,
        irr_under_panel: irr_under_panel,
        irr_between_panel: irr_between_panel,
        irr_agri_pv: irr_agri_pv,
      };
    });
  };

  const good_unite_label = () => {
    if (selectedPeriodIrrValue == "FRACTION") {
      return "Irradiance Fraction (%)";
    } else if (selectedPeriodIrrValue == "CUMULATED") {
      if (selectedPeriodIrrUnite == "IRR") {
        return "Cumulated Irradiance (Wh/m²)";
      } else if (selectedPeriodIrrUnite == "PAR") {
        return "Cumulated PAR (Wh/m²)";
      }
    } else if (selectedPeriodIrrValue == "DAILY") {
      if (selectedPeriodIrrUnite == "IRR") {
        return "Daily Irradiance (Wh/m²)";
      } else if (selectedPeriodIrrUnite == "PAR") {
        return "Daily PAR (Wh/m²)";
      }
    }
  };
  const good_unite = () => {
    if (selectedPeriodIrrValue == "FRACTION") {
      return "%";
    } else if (selectedPeriodIrrValue == "CUMULATED") {
      if (selectedPeriodIrrUnite == "IRR") {
        return "Wh/m²";
      } else if (selectedPeriodIrrUnite == "PAR") {
        return "kWh/kWc";
      }
    } else if (selectedPeriodIrrValue == "DAILY") {
      if (selectedPeriodIrrUnite == "IRR") {
        return "Wh/m²";
      } else if (selectedPeriodIrrUnite == "PAR") {
        return "kWh/kWc";
      }
    }
  };

  const [inactiveKeysPeriodIrr, setInactiveKeyPeriodIrr] = useState(
    Array<string>()
  );

  if (!tableValues.length) return <div style={{ height: "100vh" }} />;

  return (
    <div className={styled.list}>
      <div className={styled.chartContainer}>
        <div className={styled.optionContainer}>
          <div className={styled.doubleUniteSelector}>
            <div className={styled.doubleUniteSelectorItem}>
              <Select
                labelPosition="left"
                label="Value"
                options={valuePeriodIrrOptions}
                value={valuePeriodIrrOptions.find(
                  (item) => item.value == selectedPeriodIrrValue
                )}
                onChange={(e: any) => {
                  setSelectedPeriodIrrValue(e.value);
                }}
                isSearchable={false}
                menuHeight={400}
              />
            </div>
            <div className={styled.doubleUniteSelectorItem}>
              <Select
                labelPosition="left"
                label="Unit"
                options={unitePeriodIrrOptions}
                value={unitePeriodIrrOptions.find(
                  (item) => item.value == selectedPeriodIrrUnite
                )}
                onChange={(e: any) => {
                  setSelectedPeriodIrrUnite(e.value);
                }}
                isSearchable={false}
                menuHeight={400}
              />
            </div>
          </div>
          <div className={styled.downloadContainer}>
            <TooltipMUI
              title={intl.formatMessage(
                SimulationResultBodyMessages.hintChartToPng
              )}
            >
              <div
                className={styled.chartToPngButton}
                onClick={chartToPng(
                  intl.formatMessage(SimulationResultBodyMessages.irrPerPeriod)
                )}
              >
                <Icon
                  name={ICON_NAMES_ENUM.download_image}
                  className={styled.file__icon}
                />
              </div>
            </TooltipMUI>
            <TooltipMUI
              title={intl.formatMessage(
                SimulationResultBodyMessages.hintChartToCsv
              )}
            >
              <div
                className={styled.chartToPngButton}
                onClick={chartToCsv(
                  tableValues,
                  `${result.simulation.name} Irradiance per Stage (${selectedPeriodIrrUnite})`
                )}
              >
                <Icon
                  name={ICON_NAMES_ENUM.download_file}
                  className={styled.file__icon}
                />
              </div>
            </TooltipMUI>
          </div>
        </div>

        <div id={intl.formatMessage(SimulationResultBodyMessages.irrPerPeriod)}>
          <TooltipMUI
            title={intl.formatMessage(SimulationResultBodyMessages.hintLegend)}
          >
            <div className={styled.chartTitle}>
              {intl.formatMessage(SimulationResultBodyMessages.irrPerPeriod)}
            </div>
          </TooltipMUI>
          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart
              data={good_table_values()}
              width={500}
              height={700}
              margin={{
                top: 10,
                right: 30,
                left: 20,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey={"period.name"}
                tick={<CustomTick />}
                height={75}
                interval={0}
              />
              <YAxis
                // domain={[0, 100]}
                interval={0}
                yAxisId={"left"}
                orientation="left"
              >
                <Label
                  style={{ textAnchor: "middle" }}
                  position={"left"}
                  angle={270}
                  fontSize={12}
                  value={good_unite_label()}
                />
              </YAxis>
              <YAxis yAxisId="right" orientation="right" interval={0}>
                <Label
                  style={{ textAnchor: "middle" }}
                  position={"insideRight"}
                  angle={270}
                  fontSize={12}
                  value={intl.formatMessage(
                    SimulationResultBodyMessages.heterogeneityTh
                  )}
                />
              </YAxis>
              <Tooltip content={CustomTooltip} />
              <Legend
                onClick={(o) => {
                  if (
                    !inactiveKeysPeriodIrr.find(
                      (inactiveKey) => inactiveKey == o.dataKey
                    )
                  )
                    setInactiveKeyPeriodIrr([
                      ...inactiveKeysPeriodIrr,
                      o.dataKey,
                    ]);
                  else {
                    var temp = inactiveKeysPeriodIrr;
                    temp.splice(
                      inactiveKeysPeriodIrr.findIndex(
                        (inactiveKey) => inactiveKey == o.dataKey
                      ),
                      1
                    );
                    setInactiveKeyPeriodIrr([...temp]);
                  }
                }}
              />
              <Bar
                type="monotone"
                dataKey={"irr_zt"}
                name="Control Zone"
                yAxisId="left"
                fill={"#AA6666"}
                hide={
                  inactiveKeysPeriodIrr.find(
                    (inactiveKey) => inactiveKey == "irr_zt"
                  )
                    ? true
                    : false
                }
                unit={good_unite()}
              />
              <Bar
                type="monotone"
                dataKey={"irr_under_panel"}
                name="Under Panels"
                yAxisId="left"
                fill={"#66AA66"}
                hide={
                  inactiveKeysPeriodIrr.find(
                    (inactiveKey) => inactiveKey == "irr_under_panel"
                  )
                    ? true
                    : false
                }
                unit={good_unite()}
              />
              <Bar
                type="monotone"
                dataKey={"irr_between_panel"}
                name="Between Panels"
                yAxisId="left"
                fill={"#6666AA"}
                hide={
                  inactiveKeysPeriodIrr.find(
                    (inactiveKey) => inactiveKey == "irr_between_panel"
                  )
                    ? true
                    : false
                }
                unit={good_unite()}
              />
              <Bar
                type="monotone"
                dataKey={"irr_agri_pv"}
                name="AgriPV Zone"
                yAxisId="left"
                fill={"#444444"}
                hide={
                  inactiveKeysPeriodIrr.find(
                    (inactiveKey) => inactiveKey == "irr_agri_pv"
                  )
                    ? true
                    : false
                }
                unit={good_unite()}
              />
              <Line
                type="linear"
                dataKey={"heterogeneity"}
                yAxisId="right"
                hide={
                  inactiveKeysPeriodIrr.find(
                    (inactiveKey) => inactiveKey == "heterogeneity"
                  )
                    ? true
                    : false
                }
                stroke="#AA55BE"
                unit={"%"}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div>
        <div className={styled.downloadContainer}>
          <div className={styled.chartTitle}>
            {intl.formatMessage(SimulationResultBodyMessages.irrPerPeriod)}
          </div>
          <TooltipMUI
            title={intl.formatMessage(
              SimulationResultBodyMessages.hintChartToPng
            )}
          >
            <div
              className={styled.chartToPngButton}
              onClick={chartToPng(
                "Table " +
                  intl.formatMessage(SimulationResultBodyMessages.irrPerPeriod)
              )}
            >
              <Icon
                name={ICON_NAMES_ENUM.download_image}
                className={styled.file__icon}
              />
            </div>
          </TooltipMUI>
          <TooltipMUI
            title={intl.formatMessage(
              SimulationResultBodyMessages.hintChartToCsv
            )}
          >
            <div
              className={styled.chartToPngButton}
              onClick={chartToCsv(
                tableValues,
                `${result.simulation.name} Irradiance per Stage (${selectedPeriodIrrUnite})`
              )}
            >
              <Icon
                name={ICON_NAMES_ENUM.download_file}
                className={styled.file__icon}
              />
            </div>
          </TooltipMUI>
        </div>
        <div
          id={
            "Table " +
            intl.formatMessage(SimulationResultBodyMessages.irrPerPeriod)
          }
        >
          <table className={styled.dataTable}>
            <thead>
              <tr>
                <th rowSpan={2}>
                  <FormattedMessage {...CropCreateMessages.stageName} />
                </th>
                <th colSpan={4}>{good_unite_label()}</th>
              </tr>
              <tr className={styled.specialCaseTable}>
                <th>
                  <FormattedMessage
                    {...WaterBalanceResultMenuMessages.controlZoneSubTitle}
                  />
                </th>
                <th>
                  <FormattedMessage
                    {...WaterBalanceResultMenuMessages.underPanelsSubTitle}
                  />
                </th>
                <th>
                  <FormattedMessage
                    {...WaterBalanceResultMenuMessages.betweenPanelsZoneSubTitle}
                  />
                </th>
                <th>
                  <FormattedMessage
                    {...WaterBalanceResultMenuMessages.agriPVZoneSubTitle}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {good_table_values().map((values: any) => (
                <tr key={values.period.name}>
                  <td>{values.period.name}</td>
                  <td>{values.irr_zt}</td>
                  <td>{values.irr_under_panel}</td>
                  <td>{values.irr_between_panel}</td>
                  <td>{values.irr_agri_pv}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default IrradianceContent;
