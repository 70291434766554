import { ParamsInterests } from "components/BatchesWaterBalanceBody/BatchesWaterBalanceBody";
import MainContent from "components/MainContent/MainContent";
import StatusLabel, { StatusesEnum } from "components/StatusLabel/StatusLabel";
import {
  IInterestParamsWaterBalanceItem,
  useWaterBalanceParamsList,
  IInterestNameVariantsWaterBalance,
} from "components/WaterBalanceParametersInterest/useParamsList";
import { Loader } from "components/common/loader/Loader";
import { ROUTES } from "constants/routes";
import { useOptionData } from "hooks/useOptionData";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  cancelBatchWaterBalanceSimulation,
  deleteBatchWaterBalanceResult,
  getBatchWaterBalanceResults,
  resetBatchWaterBalanceResults,
} from "store/batches_water_balance/actions";
import {
  getBatchWaterBalance,
  getBatchWaterBalanceMetadata,
  getBatchWaterBalanceResultFiles,
  getBatchWaterBalanceSimulationResultFiles,
} from "store/batches_water_balance/api";
import { getBatchWaterBalanceResultsState } from "store/batches_water_balance/selectors";
import { getNavigationState } from "store/navigation/selectors";
import { downloadFile } from "utils/downloadFile";
import styled from "./styled.module.scss";
import { ReactComponent as Arrow } from "assets/icons/arrow-down.svg";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import Button from "components/common/button/Button";
import { FormattedMessage, useIntl } from "react-intl";
import CommonMessages from "components/common/CommonMessages";
import { Icon, ICON_NAMES_ENUM } from "components/common/icon/Icon";
import clsx from "clsx";
import BatchesWaterBalanceResultMessages from "./BatchesWaterBalanceResultMessages";
import ParametersInterestMessages from "components/ParametersInterest/ParametersInterestMessages";
import WaterBalanceResultBodyMessages from "components/WaterBalanceResultBody/WaterBalanceResultBodyMessages";
import { Tooltip as TooltipMUI } from "@mui/material";
import { getWaterBalance } from "store/water_balances/actions";
import Input from "components/common/input/Input";
import { getWaterBalanceBatchAnalysisTable } from "store/analisys/api";
import BatchesResultMessages from "pages/BatchesResult/BatchesResultMessages";
import { Bar, CartesianGrid, ComposedChart, Label, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import CustomTick from "components/CustomTick/CustomTick";

const REFRESH_DATA_TIME = 10000;

type simulationsProgressType = {
  current: number;
  total: number;
}[];

const BatchesWaterBalanceResult = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { client_id, project_id, batch_water_balance_id, id } = useParams();
  const params = useWaterBalanceParamsList();
  const batchResult = useSelector(getBatchWaterBalanceResultsState);
  const {
    weather_datasets,
    production_datasets,
    etp_datasets,
    batches_water_balance,
  } = useSelector(getNavigationState);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [isMetadataFileLoading, setIsMetadataFileLoading] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [isSimulationFileLoading, setIsSimulationFileLoading] = useState<
    Record<number, boolean>
  >({});
  const [isIrrigation, setIsIrrigation] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    getBatchWaterBalance({
      clientId: client_id,
      projectId: project_id,
      batchWaterBalanceId: batch_water_balance_id,
    }).then(({ data }) => {
      setIsIrrigation(data.simulation.water_balance.irrigation)
    })
  }, [client_id, project_id, batch_water_balance_id]);

  const findFullName = (findId: number) => {
    for (const batch of batches_water_balance) {
      for (const result of batch.results) {
        const index = result.simulations.findIndex(
          ({ id }: any) => id === findId
        );

        if (index !== -1) {
          return `${batch.name} - ${result.name} - ${result.simulations[index].name}`;
        }
      }
    }
    return batchResult.name;
  };

  const isSuccessSimulation = batchResult?.status === StatusesEnum.COMPLETED;
  const isCanceledSimulation = batchResult?.status === StatusesEnum.CANCELED;
  const isProgressSimulation = batchResult?.status === StatusesEnum.RUNNING;

  const { crops, universal_datasets, batches, steering_algorithms, soil_informations } =
    useSelector(getNavigationState);

  const universalDatasetOptions = useOptionData(universal_datasets);
  const cropOptions = useOptionData(crops);
  const steeringAlgorithmOptions = useOptionData(steering_algorithms);
  const soilInformationsOptions = useOptionData(soil_informations);

  const onDownloadSimulation = (simulation_water_balance_result: any) => () => {
    setIsSimulationFileLoading((prev) => ({
      ...prev,
      [simulation_water_balance_result.id]: true,
    }));
    getBatchWaterBalanceSimulationResultFiles({
      clientId: client_id,
      projectId: project_id,
      batchWaterBalanceId: batch_water_balance_id,
      resultId: id,
      simulationWaterBalanceId: simulation_water_balance_result.id,
    })
      .then((href) => {
        downloadFile(
          href,
          `${findFullName(simulation_water_balance_result.id)}.zip`
        );
      })
      .finally(() => {
        setIsSimulationFileLoading((prev) => ({
          ...prev,
          [simulation_water_balance_result.id]: false,
        }));
      });
  };

  const onDownload = () => {
    setIsFileLoading(true);
    getBatchWaterBalanceResultFiles({
      clientId: client_id,
      projectId: project_id,
      batchWaterBalanceId: batch_water_balance_id,
      resultId: id,
    })
      .then((href) => {
        downloadFile(href, `${findFullName(batchResult.name)}.zip`);
      })
      .finally(() => setIsFileLoading(false));
  };

  const onDownloadMetadata = () => {
    setIsMetadataFileLoading(true);
    getBatchWaterBalanceMetadata({
      clientId: client_id,
      projectId: project_id,
      batchWaterBalanceId: batch_water_balance_id,
      resultId: id,
    })
      .then((href) => {
        downloadFile(href, `${findFullName(batchResult.name)}.csv`);
      })
      .finally(() => setIsMetadataFileLoading(false));
  };

  const onCancel = () => {
    setIsCanceling(true);
    dispatch(
      cancelBatchWaterBalanceSimulation({
        clientId: client_id,
        projectId: project_id,
        batchWaterBalanceId: batch_water_balance_id,
        resultId: id,
        callback: () => setIsCanceling(false),
      })
    );
  };

  const onBack = () => {
    navigate(
      ROUTES.BATCHES_WATER_BALANCE_EDIT.replace(
        ":client_id",
        client_id as string
      )
        .replace(":project_id", project_id as string)
        .replace(":id", batch_water_balance_id as string)
    );
  };

  const onDelete = () => {
    dispatch(
      deleteBatchWaterBalanceResult({
        clientId: client_id,
        projectId: project_id,
        batchWaterBalanceId: batch_water_balance_id,
        resultId: id,
        callback: onBack,
      })
    );
  };

  const getResult = () => {
    dispatch(
      getBatchWaterBalanceResults({
        clientId: client_id,
        projectId: project_id,
        batchWaterBalanceId: batch_water_balance_id,
        resultId: id,
      })
    );
  };

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (isProgressSimulation) {
      timeoutId = setTimeout(getResult, REFRESH_DATA_TIME);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [batchResult]);

  useEffect(() => {
    getResult();

    return () => {
      dispatch(resetBatchWaterBalanceResults());
    };
  }, [client_id, project_id, batch_water_balance_id, id]);

  const getCurrentField = useCallback(
    (value: IInterestNameVariantsWaterBalance, rows: ParamsInterests) => {
      const param = params.find(
        ({ name }: IInterestParamsWaterBalanceItem) => name === value
      );

      if (!param) return;

      if (param.type === "input") {
        return <Input type="number" noBorder value={rows[value]} disabled />;
      }

      if (param.type === "point") {
        return (
          <div className={styled.row}>
            <Input
              className={styled.twoInput}
              type="number"
              beforeIcon="X"
              noBorder
              disabled
              defaultValue={rows[value]?.x}
            />
            <Input
              className={styled.twoInput}
              type="number"
              beforeIcon="Y"
              noBorder
              disabled
              defaultValue={rows[value]?.y}
            />
          </div>
        );
      }

      if (param.type === "crop") {
        const selectedId = rows[value];
        const selectedValue = cropOptions.find(
          (item: any) => item.value === selectedId
        );

        return selectedValue?.label;
      }

      if (param.type === "universal_dataset") {
        const selectedId = rows[value];
        const selectedValue = universalDatasetOptions.find(
          (item: any) => item.value === selectedId
        );

        return selectedValue?.label;
      }

      if (param.type === "steering_algorithm") {
        const selectedId = rows[value];
        const selectedValue = steeringAlgorithmOptions.find(
          (item: any) => item.value === selectedId
        );
        if (selectedId == null) return "None";
        return selectedValue?.label;
      }

      if (param.type === "soil_information") {
        const selectedId = rows[value];
        const selectedValue = soilInformationsOptions.find(
          (item: any) => item.value === selectedId
        );
        if (selectedId == null) return "None";
        return selectedValue?.label;
      }
    },
    [params]
  );

  const getRowBackgroundColor = (value: number | string, type: string) => {
    const sortedData = [...tableValues.map((val: any) => val[type])].sort(
      (a, b) => a - b
    );

    sortedData.filter((item) => typeof item != "string");

    if (typeof value == "string") return "white";

    const middle = Math.floor(sortedData.length / 2);
    const median =
      sortedData.length % 2 === 0
        ? (sortedData[middle - 1] + sortedData[middle]) / 2
        : sortedData[middle];

    const min = sortedData[0];
    const max = sortedData[sortedData.length - 1];

    var green, red;

    if (value === median) {
      red = green = 255;
    } else if (value <= min) {
      green = 0;
      red = 255;
    } else if (value >= max) {
      green = 255;
      red = 0;
    } else if (value <= median) {
      const intensity = (value - min) / (median - min);
      red = 255;
      green = Math.round(255 * intensity);
    } else {
      const intensity = (value - median) / (max - median);
      red = Math.round(255 * (1 - intensity));
      green = 255;
    }

    if (
      type == "heterogeneity" ||
      type === "GCR" ||
      type === "etp_fraction"
    )
      return `rgb(${green}, ${red}, 0)`;
    else return `rgb(${red}, ${green}, 0)`;
  };

  const [tableValues, setTableValues] = useState<any[]>([])

  useEffect(() => {
    if (isSuccessSimulation)
      getWaterBalanceBatchAnalysisTable({
        clientId: client_id,
        projectId: project_id,
        batch_water_balance_id: batch_water_balance_id,
        id: id,
      }).then((res) => setTableValues(res.data));
  }, [batchResult])

  if (!batchResult) return <Loader />;

  const simulationsProgressData: simulationsProgressType = batchResult.rows.map(
    ({ simulation_water_balance_result }: any) => {
      const isRunningMain =
        simulation_water_balance_result?.status === StatusesEnum.RUNNING_MAIN;
      const isPrepare =
        simulation_water_balance_result?.status === StatusesEnum.PREPARE;
      const isCompleted =
        simulation_water_balance_result?.status === StatusesEnum.COMPLETED;

      const sumPeriodsPath =
        simulation_water_balance_result?.result_data?.reduce(
          (a: any, { current }: any) => {
            a += current;
            return a;
          },
          0
        );

      const mainPath =
        isRunningMain || isPrepare
          ? simulation_water_balance_result.current
          : simulation_water_balance_result.length;

      const current = isCompleted
        ? simulation_water_balance_result.total_length || 100
        : mainPath + sumPeriodsPath || 0;

      return {
        current,
        total: simulation_water_balance_result.total_length || 100,
      };
    }
  );

  const simulationsProgress = simulationsProgressData.map(
    ({ current, total }) => (current / (total / 100)).toFixed(0)
  );

  const totalProgressData = simulationsProgressData.reduce(
    (a, c) => {
      a.current += c.current;
      a.total += c.total;
      return a;
    },
    { current: 0, total: 0 }
  );

  const totalProgress = (
    totalProgressData.current /
    (totalProgressData.total / 100)
  ).toFixed(0);

  const chartToCsv = (chartData: any) => () => {
    const generateCSV = (data: any) => {
      let csvContent = "";

      if (data.length === 0) {
        return csvContent;
      }
      const keys = Object.keys(data[0]);
      csvContent += keys.join(",") + "\n";
      data.forEach((item: any) => {
        const row = keys.map((key) => item[key]);
        csvContent += row.join(",") + "\n";
      });
      return csvContent;
    };

    const saveFile = (file: any) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      link.download = file.name;
      link.click();
    };

    const csvData = generateCSV(chartData);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], "data.csv", {
          type: "text/csv;charset=utf-8",
        });
        URL.revokeObjectURL(url);
        saveFile(file);
      });
  };

  return (
    <MainContent
      header={
        <>
          <div className={styled.row}>
            <h2 className={styled.title}>{batchResult.name}</h2>
            <StatusLabel status={batchResult.status} />
            {!isSuccessSimulation && (
              <span className={styled.progressText}>{totalProgress}%</span>
            )}
          </div>
          <div className={styled.headerRow}>
            {(isSuccessSimulation || isCanceledSimulation) && (
              <Button
                variant="text"
                iconBefore={<Delete />}
                onClick={onDelete}
                className={styled.deleteButton}
              >
                <FormattedMessage {...CommonMessages.delete} />
              </Button>
            )}

            {isProgressSimulation && (
              <Button
                variant="outlined"
                disabled={isCanceling}
                isLoading={isCanceling}
                onClick={onCancel}
              >
                <FormattedMessage {...CommonMessages.cancel} />
              </Button>
            )}

            {isSuccessSimulation && (
              <div style={{ display: "flex", gap: "1rem" }}>
                <Button
                  iconBefore={<Icon name={ICON_NAMES_ENUM.arrow_down} />}
                  disabled={isFileLoading}
                  isLoading={isFileLoading}
                  onClick={onDownload}
                >
                  <FormattedMessage {...CommonMessages.downloadAll} />
                </Button>
                <Button
                  iconBefore={<Icon name={ICON_NAMES_ENUM.arrow_down} />}
                  disabled={true}
                  isLoading={isMetadataFileLoading}
                  onClick={onDownloadMetadata}
                  className={styled.disable}
                >
                  <FormattedMessage {...CommonMessages.downloadAllMetadata} />
                </Button>
              </div>
            )}
          </div>
        </>
      }
    >
      <div className={styled.tableContainer}>
        <table className={styled.table}>
          <thead>
            <tr>
              <th>
                <FormattedMessage
                  {...BatchesWaterBalanceResultMessages.waterBalancePage}
                />
              </th>

              {Object.keys(batchResult.rows[0].batch_water_balance_row).map(
                (item) => (
                  <th key={item}>
                    <FormattedMessage
                      {...ParametersInterestMessages[
                      item as IInterestNameVariantsWaterBalance
                      ]}
                    />
                  </th>
                )
              )}

              <th>
                <FormattedMessage
                  {...BatchesWaterBalanceResultMessages.status}
                />
              </th>

              <th className={styled.lastColumn} />
            </tr>
          </thead>

          <tbody>
            {batchResult.rows.map((simulation: any, index: number) => (
              <tr
                key={simulation.simulation_water_balance_result.id}
                className={clsx({
                  [styled.successRow]:
                    simulation.simulation_water_balance_result.status ===
                    StatusesEnum.COMPLETED,
                  [styled.failedRow]:
                    simulation.simulation_water_balance_result.status ===
                    StatusesEnum.FAILED,
                })}
              >
                <td>
                  <Link
                    className={styled.link}
                    to={ROUTES.BATCHES_WATER_BALANCE_SIMULATION_RESULT.replace(
                      ":client_id",
                      client_id as string
                    )
                      .replace(":project_id", project_id as string)
                      .replace(
                        ":batch_water_balance_id",
                        batch_water_balance_id as string
                      )
                      .replace(":result_id", id as string)
                      .replace(
                        ":simulation_water_balance_id",
                        simulation.simulation_water_balance_result.id as string
                      )}
                  >
                    <span>
                      {simulation.simulation_water_balance_result.name}
                    </span>
                    <LinkIcon />
                  </Link>
                </td>
                {Object.keys(simulation.batch_water_balance_row).map((item) => (
                  <td
                    key={`${item}-${simulation.simulation_water_balance_result.id}`}
                  >
                    {getCurrentField(
                      item as IInterestNameVariantsWaterBalance,
                      simulation.batch_water_balance_row
                    )}
                  </td>
                ))}
                <td>
                  <StatusLabel
                    status={
                      simulation.simulation_water_balance_result
                        .status as StatusesEnum
                    }
                  />
                </td>
                <td>
                  {simulation.simulation_water_balance_result.status ===
                    StatusesEnum.COMPLETED ? (
                    <Button
                      variant="text"
                      disabled={
                        isSimulationFileLoading[
                        simulation.simulation_water_balance_result.id
                        ]
                      }
                      isLoading={
                        isSimulationFileLoading[
                        simulation.simulation_water_balance_result.id
                        ]
                      }
                      className={clsx(styled.button)}
                      onClick={onDownloadSimulation(
                        simulation.simulation_water_balance_result
                      )}
                    >
                      <Arrow />
                    </Button>
                  ) : (
                    <span className={styled.progressText}>
                      {simulationsProgress[index]}%
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <br />
      {!!tableValues.length && <div className={styled.tableContainer}>
        <table className={styled.dataTable} id="tab1">
          <thead>
            <tr>
              <th>
                <FormattedMessage {...CommonMessages.simulation} />
              </th>

              {Object.keys(batchResult.rows[0].batch_water_balance_row).map((item) => (
                <th key={item}>
                  <FormattedMessage
                    {...ParametersInterestMessages[
                    item as IInterestNameVariantsWaterBalance
                    ]}
                  />
                </th>
              ))}
              <th>
                <TooltipMUI
                  title={intl.formatMessage(
                    BatchesResultMessages.irradianceFractionHelp
                  )}
                >
                  <div>
                    <div
                      className={`${styled.flex} ${styled.justifyCenter}`}
                    >
                      <FormattedMessage
                        {...BatchesResultMessages.irradianceFraction}
                      />
                      <div className={styled["help-icon"]}>
                        <Icon
                          name={ICON_NAMES_ENUM.help_circle}
                          className={styled.file__icon}
                        />
                      </div>
                    </div>
                    <div className={styled.subTitle}>
                      <FormattedMessage
                        {...BatchesResultMessages.ETPAgriPVZoneSubTitle}
                      />
                    </div>
                  </div>
                </TooltipMUI>
              </th>
              <th>
                <TooltipMUI
                  title={intl.formatMessage(
                    BatchesResultMessages.meanDailyPARHelp
                  )}
                >
                  <div>
                    <div
                      className={`${styled.flex} ${styled.justifyCenter}`}
                    >
                      <FormattedMessage
                        {...BatchesResultMessages.meanDailyPAR}
                      />

                      <div className={styled["help-icon"]}>
                        <Icon
                          name={ICON_NAMES_ENUM.help_circle}
                          className={styled.file__icon}
                        />
                      </div>
                    </div>
                    <div className={styled.subTitle}>
                      <FormattedMessage
                        {...BatchesResultMessages.ETPAgriPVZoneSubTitle}
                      />
                    </div>
                  </div>
                </TooltipMUI>
              </th>
              <th>
                <TooltipMUI
                  title={intl.formatMessage(
                    BatchesResultMessages.heterogeneityHelp
                  )}
                >
                  <div>
                    <div
                      className={`${styled.flex} ${styled.justifyCenter}`}
                    >
                      <FormattedMessage
                        {...BatchesResultMessages.heterogeneity}
                      />

                      <div className={styled["help-icon"]}>
                        <Icon
                          name={ICON_NAMES_ENUM.help_circle}
                          className={styled.file__icon}
                        />
                      </div>
                    </div>
                  </div>
                </TooltipMUI>
              </th>
              <th>
                <TooltipMUI
                  title={intl.formatMessage(
                    BatchesResultMessages.ETPAgriPVZoneHelp
                  )}
                >
                  <div>
                    <div
                      className={`${styled.flex} ${styled.justifyCenter}`}
                    >
                      <FormattedMessage
                        {...BatchesResultMessages.ETPAgriPVZone}
                      />

                      <div className={styled["help-icon"]}>
                        <Icon
                          name={ICON_NAMES_ENUM.help_circle}
                          className={styled.file__icon}
                        />
                      </div>
                    </div>

                    <div className={styled.subTitle}>
                      <FormattedMessage
                        {...BatchesResultMessages.ETPAgriPVZoneSubTitle}
                      />
                    </div>
                  </div>
                </TooltipMUI>
              </th>
              <th>
                <TooltipMUI
                  title={intl.formatMessage(
                    BatchesResultMessages.waterDeficitHelp
                  )}
                >
                  <div>
                    <div
                      className={`${styled.flex} ${styled.justifyCenter}`}
                    >
                      <FormattedMessage
                        {...BatchesResultMessages.waterDeficit}
                      />
                      <div className={styled["help-icon"]}>
                        <Icon
                          name={ICON_NAMES_ENUM.help_circle}
                          className={styled.file__icon}
                        />
                      </div>
                    </div>
                    <div className={styled.subTitle}>
                      <FormattedMessage
                        {...BatchesResultMessages.waterDeficitSubTitle}
                      />
                    </div>
                  </div>
                </TooltipMUI>
              </th>
            </tr>
          </thead>

          <tbody>
            {batchResult.rows.map((simulation: any, index: number) => (
              <tr key={tableValues[index].simulation_name}>
                <td>
                  <span>{tableValues[index].simulation_name}</span>
                </td>
                {Object.keys(simulation.batch_water_balance_row).map((item) => (
                  <td key={`${item}-${tableValues[index].simulation_name}`}>
                    {getCurrentField(
                      item as IInterestNameVariantsWaterBalance,
                      simulation.batch_water_balance_row
                    )}
                  </td>
                ))}
                <td
                  style={{
                    backgroundColor: getRowBackgroundColor(
                      tableValues[index].irr_fraction,
                      "irr_fraction"
                    ),
                  }}
                >
                  {
                    // IRR FRACTION
                    tableValues[index].irr_fraction
                  }
                </td>
                <td
                  style={{
                    backgroundColor: getRowBackgroundColor(
                      tableValues[index].mean_daily_par,
                      "mean_daily_par"
                    ),
                  }}
                >
                  {
                    // DAILY PAR
                    tableValues[index].mean_daily_par
                  }
                </td>
                <td
                  style={{
                    backgroundColor: getRowBackgroundColor(
                      tableValues[index].heterogeneity,
                      "heterogeneity"
                    ),
                  }}
                >
                  {
                    tableValues[index].heterogeneity
                  }
                </td>
                <td
                  style={{
                    backgroundColor: getRowBackgroundColor(
                      tableValues[index].etp_fraction,
                      "etp_fraction"
                    ),
                  }}
                >
                  {
                    // ETP FRACTION
                    tableValues[index].etp_fraction
                  }
                </td>
                <td
                  style={{
                    backgroundColor: getRowBackgroundColor(
                      tableValues[index].water_deficit,
                      "water_deficit"
                    ),
                  }}
                >
                  {tableValues[index].water_deficit}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <table className={styled.dataTable} id="tab2">
          <thead>
            <tr>
              <th>
                <FormattedMessage {...CommonMessages.simulation} />
              </th>

              {Object.keys(batchResult.rows[0].batch_water_balance_row).map((item) => (
                <th key={item}>
                  <FormattedMessage
                    {...ParametersInterestMessages[
                    item as IInterestNameVariantsWaterBalance
                    ]}
                  />
                </th>
              ))}
              <th>
                <TooltipMUI
                  title={intl.formatMessage(
                    BatchesResultMessages.farmingZoneFractionHelp
                  )}
                >
                  <div className={`${styled.flex} ${styled.justifyCenter}`}>
                    <FormattedMessage
                      {...BatchesResultMessages.farmingZoneFraction}
                    />
                    <div className={styled["help-icon"]}>
                      <Icon
                        name={ICON_NAMES_ENUM.help_circle}
                        className={styled.file__icon}
                      />
                    </div>
                  </div>
                </TooltipMUI>
              </th>
              <th>
                <TooltipMUI
                  title={intl.formatMessage(
                    BatchesResultMessages.groundCoverageRatioHelp
                  )}
                >
                  <div className={`${styled.flex} ${styled.justifyCenter}`}>
                    <FormattedMessage
                      {...BatchesResultMessages.groundCoverageRatio}
                    />
                    <div className={styled["help-icon"]}>
                      <Icon
                        name={ICON_NAMES_ENUM.help_circle}
                        className={styled.file__icon}
                      />
                    </div>
                  </div>
                </TooltipMUI>
              </th>
              <th>
                <TooltipMUI
                  title={intl.formatMessage(
                    BatchesResultMessages.installedCapacityHelp
                  )}
                >
                  <div className={`${styled.flex} ${styled.justifyCenter}`}>
                    <FormattedMessage
                      {...BatchesResultMessages.installedCapacity}
                    />
                    <div className={styled["help-icon"]}>
                      <Icon
                        name={ICON_NAMES_ENUM.help_circle}
                        className={styled.file__icon}
                      />
                    </div>
                  </div>
                </TooltipMUI>
              </th>
            </tr>
          </thead>

          <tbody>
            {batchResult.rows.map((simulation: any, index: number) => (
              <tr key={tableValues[index].simulation_name}>
                <td>
                  <span>{tableValues[index].simulation_name}</span>
                </td>
                {Object.keys(simulation.batch_water_balance_row).map((item) => (
                  <td key={`${item}-${tableValues[index].simulation_name}`}>
                    {getCurrentField(
                      item as IInterestNameVariantsWaterBalance,
                      simulation.batch_water_balance_row
                    )}
                  </td>
                ))}
                <td
                  style={{
                    backgroundColor: getRowBackgroundColor(
                      tableValues[index].zone_cultivable,
                      "zone_cultivable"
                    ),
                  }}
                >
                  {
                    // % zone cultivable
                    tableValues[index].zone_cultivable
                  }
                </td>
                <td
                  style={{
                    backgroundColor: getRowBackgroundColor(
                      tableValues[index].GCR,
                      "GCR"
                    ),
                  }}
                >
                  {
                    // TAUX DE COUVERTURE
                    tableValues[index].GCR
                  }
                </td>
                <td
                  style={{
                    backgroundColor: getRowBackgroundColor(
                      tableValues[index].installed_capacity,
                      "installed_capacity"
                    ),
                  }}
                >
                  {
                    // PUISSANCE INSTALLEE
                    tableValues[index].installed_capacity
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <div className={`${styled.flex} ${styled.spaceBetween}`}>
          <div className={styled.halfChartContainer}>
            <div className={styled.title}>
              <FormattedMessage
                {...BatchesResultMessages.fractionAndHeterogeneityOfIrradiance}
              />
            </div>
            <div className={styled.chart} id="graph1">
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  width={500}
                  height={500}
                  data={tableValues.map((val: any, index: number) => ({
                    name: batchResult.rows[index].simulation_water_balance_result.name,
                    IRR_FRACTION: val.irr_fraction,
                    HETEROGENEITY: val.heterogeneity,
                  }))}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    height={75}
                    dataKey="name"
                    interval={0}
                    tick={<CustomTick />}
                  />
                  <YAxis
                    domain={[0, 100]}
                    yAxisId={"left"}
                    orientation="left"
                  >
                    <Label
                      style={{
                        textAnchor: "middle",
                      }}
                      position={"insideLeft"}
                      angle={270}
                      value={intl.formatMessage(
                        BatchesResultMessages.irradianceFraction
                      )}
                    />
                  </YAxis>
                  <YAxis yAxisId={"right"} orientation="right">
                    <Label
                      style={{
                        textAnchor: "middle",
                      }}
                      position={"insideRight"}
                      angle={270}
                      value={intl.formatMessage(
                        BatchesResultMessages.heterogeneity
                      )}
                    />
                  </YAxis>
                  <Tooltip />
                  <Legend />
                  <Bar
                    yAxisId={"left"}
                    dataKey="IRR_FRACTION"
                    fill="#AA6666"
                    name={intl.formatMessage(
                      BatchesResultMessages.irradianceFraction
                    )}
                  />
                  <Line
                    yAxisId={"right"}
                    dot={({ cx, cy }) => {
                      return (
                        <circle
                          key={cx.toString()}
                          cx={cx}
                          cy={cy}
                          r={6}
                          fill="#66AA66"
                        />
                      );
                    }}
                    type="monotone"
                    dataKey={"HETEROGENEITY"}
                    name={intl.formatMessage(
                      BatchesResultMessages.heterogeneity
                    )}
                    stroke={"#66AA66"}
                  // activeBar={<Rectangle fill="pink" stroke="blue" />}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className={styled.halfChartContainer}>
            <div className={styled.title}>
              <FormattedMessage {...BatchesResultMessages.ETPFraction} />{" "}
              <FormattedMessage
                {...BatchesResultMessages.ETPAgriPVZoneSubTitle}
              />
            </div>
            <div className={styled.chart} id="graph2">
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  width={500}
                  height={500}
                  data={tableValues.map((val: any, index: number) => ({
                    name: batchResult.rows[index].simulation_water_balance_result.name,
                    ETP_FRACTION: val.etp_fraction,
                  }))}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    height={75}
                    dataKey="name"
                    interval={0}
                    tick={<CustomTick />}
                  />
                  <YAxis domain={[0, 100]}>
                    <Label
                      style={{
                        textAnchor: "middle",
                      }}
                      position={"insideLeft"}
                      angle={270}
                      value={intl.formatMessage(
                        BatchesResultMessages.ETPAgriPVZone
                      )}
                    />
                  </YAxis>
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="ETP_FRACTION"
                    fill="#66AA66"
                    name={intl.formatMessage(
                      BatchesResultMessages.ETPAgriPVZone
                    )}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <br />
        <div className={`${styled.flex} ${styled.spaceBetween}`}>
          <div className={styled.halfChartContainer}>
            <div className={styled.title}>
              <FormattedMessage
                {...BatchesResultMessages.waterDeficitGraphTitle}
              />
            </div>
            <div className={styled.chart} id="graph3">
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  width={500}
                  height={500}
                  data={tableValues.map((val: any, index: number) => ({
                    name: batchResult.rows[index].simulation_water_balance_result.name,
                    WATER_DEFICIT: val.water_deficit,
                  }))}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    height={75}
                    dataKey="name"
                    interval={0}
                    tick={<CustomTick />}
                  />
                  <YAxis>
                    <Label
                      style={{
                        textAnchor: "middle",
                      }}
                      position={"insideLeft"}
                      angle={270}
                      value={intl.formatMessage(
                        BatchesResultMessages.waterDeficit
                      )}
                    />
                  </YAxis>
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="WATER_DEFICIT"
                    fill="#6666AA"
                    name={intl.formatMessage(
                      BatchesResultMessages.waterDeficit
                    )}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className={styled.halfChartContainer}>
            <div className={styled.title}>
              <FormattedMessage
                {...BatchesResultMessages.GCRAndInstalledCapacity}
              />
            </div>
            <div className={styled.chart} id="graph4">
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  width={500}
                  height={500}
                  data={tableValues.map((val: any, index: number) => ({
                    name: batchResult.rows[index].simulation_water_balance_result.name,
                    TAUX_DE_COUVERTURE: val.GCR,
                    PUISSANCE_INSTALLEE: val.installed_capacity,
                  }))}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    height={75}
                    dataKey="name"
                    interval={0}
                    tick={<CustomTick />}
                  />
                  <YAxis domain={[0, 100]} yAxisId="left" orientation="left">
                    <Label
                      style={{
                        textAnchor: "middle",
                      }}
                      position={"insideLeft"}
                      angle={270}
                      value={intl.formatMessage(
                        BatchesResultMessages.groundCoverageRatio
                      )}
                    />
                  </YAxis>
                  <YAxis yAxisId="right" orientation="right">
                    <Label
                      style={{
                        textAnchor: "middle",
                      }}
                      position={"insideRight"}
                      angle={270}
                      value={intl.formatMessage(
                        BatchesResultMessages.installedCapacity
                      )}
                    />
                  </YAxis>
                  <Tooltip />
                  <Legend />

                  <Bar
                    dataKey="TAUX_DE_COUVERTURE"
                    fill="#444444"
                    name={intl.formatMessage(
                      BatchesResultMessages.groundCoverageRatio
                    )}
                    yAxisId="left"
                  />
                  <Line
                    dot={({ cx, cy }) => {
                      return (
                        <circle
                          key={cx.toString()}
                          cx={cx}
                          cy={cy}
                          r={6}
                          fill="#66AA66"
                        />
                      );
                    }}
                    type="monotone"
                    dataKey={"PUISSANCE_INSTALLEE"}
                    name={intl.formatMessage(
                      BatchesResultMessages.installedCapacity
                    )}
                    stroke={"#66AA66"}
                    yAxisId="right"
                  // activeBar={<Rectangle fill="pink" stroke="blue" />}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>}
    </MainContent >
  );
};

export default BatchesWaterBalanceResult;
