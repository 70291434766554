import { FormikHelpers, useFormik } from "formik";
import DatasetAnalysisSchema from "pages/DatasetEdit/DatasetAnalysisSchema";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import styled from './styled.module.scss'
import Select from "components/common/select/Select";
import { useDispatch, useSelector } from "react-redux";
import { getAnalysisTable, getDatasetList, isLoadingDatasets } from "store/datasets/selectors";
import { getDatasetAnalysis } from "store/datasets/actions";
import { useParams } from "react-router-dom";
import { getNavigationState } from "store/navigation/selectors";
import { getTimezonesList } from "store/datasets/selectors";
import { useOptionData } from "hooks/useOptionData";
import clsx from "clsx";
import Button from "components/common/button/Button";
import { Icon, ICON_NAMES_ENUM } from "components/common/icon/Icon";
import { Loader } from "components/common/loader/Loader";
import { Spinner } from "react-bootstrap";
import DatasetAnalysisMessages from "./DatasetAnalysisMessages";

const DatasetAnalysis = ({ datasetStore }: any) => {
	const intl = useIntl()
	const { client_id, project_id, id } = useParams();
	const { universal_datasets } = useSelector(getNavigationState);
	const analysisTable = useSelector(getAnalysisTable);
	const isAnalysisLoading = useSelector(isLoadingDatasets);

	const dispatch = useDispatch()

	const universal_dataset_options = useOptionData(universal_datasets)

	const [otherDataset, setOtherDataset] = useState(false)

	// useEffect(() => {
	// 	dispatch(
	// 		getDatasets({
	// 			clientId: client_id,
	// 			projectId: project_id,
	// 			type: ["weather", "production", "ETP"],
	// 		})
	// 	);
	// }, [client_id, project_id]);

	const onStartAnalysis = (values: any, actions: FormikHelpers<any>) => {

	}

	const onAddOtherDatasets = () => {
		// formik_analysis.setValues({ ...formik_analysis.values, other_ETP_dataset_id: 0, other_weather_dataset_id: 0 })
		setOtherDataset(true)
	}


	const onDeleteOtherDataset = () => {
		// formik_analysis.setValues({ ...formik_analysis.values, other_ETP_dataset_id: null, other_weather_dataset_id: null })
		setOtherDataset(false)
	}



	const formik_analysis = useFormik({
		validationSchema: DatasetAnalysisSchema(intl),
		onSubmit: onStartAnalysis,
		initialValues: {
			universal_dataset_id: datasetStore.id,
			other_universal_dataset_id: null
		},
	});

	useEffect(() => {
		let timeoutId: any = null
		if (formik_analysis.values.universal_dataset_id)
			timeoutId = setTimeout(() => {
				dispatch(getDatasetAnalysis({ clientId: client_id, projectId: project_id, ...formik_analysis.values }))
			}, 500)
		if (timeoutId)
			return () => clearTimeout(timeoutId)
	}, [formik_analysis.values])


	const hasSecondDataset = formik_analysis.values.other_universal_dataset_id != null


	return <form className={styled.flex}>
		<div className={styled.tableContainer}>
			<table>
				<thead>
					<tr>
						<th className={styled.no_border} />
						<th className={styled.no_border} />
						<th className={styled.no_border} style={{ width: "300px" }}>
							{<Select
								isLoading={isAnalysisLoading}
								options={[{ value: null, label: 'None' }, ...universal_dataset_options.filter((item: any) => item.value != id)]}
								value={universal_dataset_options.filter((item: any) => item.value != id).find(item => item.value == formik_analysis.values.other_universal_dataset_id) || { value: null, label: 'None' }}
								onChange={(e: any) => {
									formik_analysis.setFieldValue("other_universal_dataset_id", e.value);
								}}
							/>}
						</th>
					</tr>
					<tr>
						<th>Indicateur</th>
						<th>Main Dataset</th>
						<th>
							<div className={styled.label}>
								Second Dataset
							</div>
						</th>
						<th>
							<div className={styled.label}>
								Diff
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					{
						Object.keys(analysisTable).filter((key) => key != "deficit_hydrique").map((key, index, keys) => <tr key={key}>
							<td>
								<FormattedMessage {...DatasetAnalysisMessages[key as keyof typeof DatasetAnalysisMessages]} />
							</td>
							<td>
								{analysisTable[key].main}
							</td>
							{analysisTable[key].other == null && !index && <td colSpan={2} rowSpan={keys.length}> Select Second Dataset </td>}
							{analysisTable[key].other != null && <>
								<td>
									{analysisTable[key].other}
								</td>
								<td>
									{analysisTable[key].diff}
								</td>
							</>}
						</tr>)
					}
				</tbody>
			</table>
		</div>
	</form >
}

export default DatasetAnalysis

function dispatch(arg0: any) {
	throw new Error("Function not implemented.");
}
