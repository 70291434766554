import styled from "./styled.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Bar,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Label,
  Text,
  BarChart,
} from "recharts";
import { default as TooltipMUI } from "@mui/material/Tooltip";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import CustomTick from "components/CustomTick/CustomTick";
import chartToCsv from "utils/chartToCsv";
import chartToPng from "utils/chartToPng";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getThermalStressTable } from "store/analisys/api";
import WaterBalanceResultBodyMessages from "components/WaterBalanceResultBody/WaterBalanceResultBodyMessages";
import CropCreateMessages from "pages/CropsCreate/CropCreateMessages";
import SimulationResultBodyMessages from "components/SimulationResultBody/SimulationResultBodyMessages";
import WaterBalanceResultMenuMessages from "./WaterBalanceResultMenuMessages";
import Select from "components/common/select/Select";

class ThermalStressContentProps {
  result: any;
}

const ThermalStress = ({ result }: ThermalStressContentProps) => {
  const intl = useIntl();
  const ThermalTypeOptions = [
    { label: "Heat stress", value: "HEAT" },
    { label: "Frost", value: "FROST" },
  ];
  const [selectedThermalTypeOptions, setSelectedThermalTypeOptions] = useState<
    "HEAT" | "FROST"
  >("HEAT");

  const CustomTooltipStress = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className={styled.customTooltip}>
          <p className={styled.label}>{label}</p>
          <div>
            {payload.map((pld: any) => (
              <div className={styled.flex} id={pld.name}>
                <div>{pld.name} :</div>
                <div
                  style={{
                    color: pld.stroke ? pld.stroke : pld.fill,
                  }}
                >
                  {pld.value} {pld.unit}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  const tablesDatas = result.thermal_stress
    .filter(
      (elem: any) => elem.period_name !== "___1periodAsYear-forWaterBalance___"
    )
    .map((period: any) => {
      return {
        period_name: period.period_name,
        control_zone_twenty_five_degres: period.control_zone_twenty_five_degres,
        control_zone_zero_degres: period.control_zone_zero_degres,
        agripv_twenty_five_degres: period.agripv_twenty_five_degres,
        agripv_zero_degres: period.agripv_zero_degres,
        period_days:
          Math.round(
            (new Date(period.period_end_date).getTime() -
              new Date(period.period_start_date).getTime()) /
            60000 /
            60 /
            24
          ) + 1,
      };
    });

  const { client_id, project_id, water_balance_id, id, simulation_water_balance_id } = useParams();
  const [tableValues, setTableValues] = useState([]);

  useEffect(() => {
    getThermalStressTable({
      clientId: client_id,
      projectId: project_id,
      simulationId: water_balance_id,
      id: id || simulation_water_balance_id,
    }).then((res) => setTableValues(res.data));
  }, [client_id, project_id, water_balance_id, id]);

  return (
    <div className={styled.list}>
      <div className={styled.optionContainer}>
        <div className={styled.uniteSelector}>
          <Select
            label="Select Chart Type"
            labelPosition="left"
            options={ThermalTypeOptions}
            value={ThermalTypeOptions.find(
              (item) => item.value == selectedThermalTypeOptions
            )}
            onChange={(e: any) => setSelectedThermalTypeOptions(e.value)}
            isSearchable={false}
            menuHeight={400}
          />
        </div>
      </div>
      {tablesDatas && tablesDatas.length > 0 && (
        <div className={styled.wrapper} style={{ display: "flex" }}>
          {selectedThermalTypeOptions == "FROST" && (
            <div className={styled.chartContainer}>
              <div style={{ width: "100%", height: "100%" }}>
                <div className={styled.optionContainer}>
                  <div className={styled.chartTitle}>
                    {intl.formatMessage(
                      WaterBalanceResultBodyMessages.titleThermalStressFreeze
                    )}
                  </div>
                  <div className={styled.downloadContainer}>
                    <TooltipMUI
                      title={intl.formatMessage(
                        WaterBalanceResultBodyMessages.hintChartToPng
                      )}
                    >
                      <div
                        className={styled.chartToPngButton}
                        onClick={chartToPng("FrostStressChart")}
                      >
                        <Icon
                          name={ICON_NAMES_ENUM.download_image}
                          className={styled.file__icon}
                        />
                      </div>
                    </TooltipMUI>
                  </div>
                </div>
                <div id={"FrostStressChart"}>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={tablesDatas}
                      width={500}
                      height={700}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 20,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey={"period_name"}
                        tick={<CustomTick />}
                        height={75}
                        interval={0}
                      />
                      <YAxis yAxisId="left" interval={0} orientation="left">
                        <Label
                          fontSize={12}
                          style={{ textAnchor: "middle" }}
                          position={"insideLeft"}
                          angle={270}
                          value="DAYS"
                        />
                      </YAxis>
                      <Tooltip content={CustomTooltipStress} />
                      <Legend
                        payload={[
                          {
                            value: "Control Zone",
                            color: "#156082",
                          },
                          {
                            value: "AgriPV Zone",
                            color: "#0F9ED5",
                          },
                        ]}
                      />
                      <Bar
                        type="monotone"
                        dataKey={"control_zone_zero_degres"}
                        name="Control Zone"
                        yAxisId="left"
                        fill={"#156082"}
                        unit={"days"}
                      />
                      <Bar
                        type="monotone"
                        dataKey={"agripv_zero_degres"}
                        name="AgriPV Zone"
                        yAxisId="left"
                        fill={"#0F9ED5"}
                        unit={"days"}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          )}
          {selectedThermalTypeOptions == "HEAT" && (
            <div className={styled.chartContainer}>
              <div style={{ width: "100%", height: "100%" }}>
                <div className={styled.optionContainer}>
                  <div className={styled.chartTitle}>
                    {intl.formatMessage(
                      WaterBalanceResultBodyMessages.titleThermalStressHot
                    )}
                  </div>
                  <div className={styled.downloadContainer}>
                    <TooltipMUI
                      title={intl.formatMessage(
                        WaterBalanceResultBodyMessages.hintChartToPng
                      )}
                    >
                      <div
                        className={styled.chartToPngButton}
                        onClick={chartToPng("HeatStressChart")}
                      >
                        <Icon
                          name={ICON_NAMES_ENUM.download_image}
                          className={styled.file__icon}
                        />
                      </div>
                    </TooltipMUI>
                  </div>
                </div>
                <div id={"HeatStressChart"}>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={tablesDatas}
                      width={500}
                      height={500}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 20,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey={"period_name"}
                        tick={<CustomTick />}
                        height={75}
                        interval={0}
                      />
                      <YAxis yAxisId="left" interval={0} orientation="left">
                        <Label
                          fontSize={12}
                          style={{ textAnchor: "middle" }}
                          position={"insideLeft"}
                          angle={270}
                          value="DAYS"
                        />
                      </YAxis>
                      <Tooltip content={CustomTooltipStress} />
                      <Legend
                        payload={[
                          {
                            value: "Control Zone",
                            color: "#FFC000",
                          },
                          {
                            value: "AgriPV Zone",
                            color: "#FF0000",
                          },
                        ]}
                      />
                      <Bar
                        type="monotone"
                        dataKey={"control_zone_twenty_five_degres"}
                        name="Control Zone"
                        yAxisId="left"
                        fill={"#FFC000"}
                        unit={"days"}
                      />
                      <Bar
                        type="monotone"
                        dataKey={"agripv_twenty_five_degres"}
                        name="AgriPV Zone"
                        yAxisId="left"
                        fill={"#FF0000"}
                        unit={"days"}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <div className={styled.tableContainer}>
        {tablesDatas && tablesDatas.length > 0 && (
          <div className={styled.tableContainer}>
            <div className={styled.downloadContainer}>
              <div className={styled.chartTitle}>
                <FormattedMessage
                  {...WaterBalanceResultBodyMessages.titleThermalTable}
                />
              </div>
              <TooltipMUI
                title={intl.formatMessage(
                  WaterBalanceResultBodyMessages.hintChartToPng
                )}
              >
                <div
                  className={styled.chartToPngButton}
                  onClick={chartToPng("Table thermal stress")}
                >
                  <Icon
                    name={ICON_NAMES_ENUM.download_image}
                    className={styled.file__icon}
                  />
                </div>
              </TooltipMUI>
              <TooltipMUI
                title={intl.formatMessage(
                  WaterBalanceResultBodyMessages.hintChartToCsv
                )}
              >
                <div
                  className={styled.chartToPngButton}
                  onClick={chartToCsv(
                    tableValues,
                    `${result.simulation.name} thermal stress`
                  )}
                >
                  <Icon
                    name={ICON_NAMES_ENUM.download_file}
                    className={styled.file__icon}
                  />
                </div>
              </TooltipMUI>
            </div>

            <table className={styled.dataTable} id="Table thermal stress">
              <thead>
                <tr>
                  <th rowSpan={2}>
                    <FormattedMessage {...CropCreateMessages.stageName} />
                  </th>
                  <th colSpan={2}>
                    <TooltipMUI
                      title={intl.formatMessage(
                        WaterBalanceResultBodyMessages.heatStressHelp
                      )}
                    >
                      <div>
                        <div
                          className={`${styled.flex} ${styled.justifyCenter}`}
                        >
                          <FormattedMessage
                            {...WaterBalanceResultBodyMessages.heatStress}
                          />
                          <div className={styled["help-icon"]}>
                            <Icon
                              name={ICON_NAMES_ENUM.help_circle}
                              className={styled.file__icon}
                            />
                          </div>
                        </div>
                      </div>
                    </TooltipMUI>
                  </th>
                  <th colSpan={2}>
                    <TooltipMUI
                      title={intl.formatMessage(
                        WaterBalanceResultBodyMessages.frostStressHelp
                      )}
                    >
                      <div>
                        <div
                          className={`${styled.flex} ${styled.justifyCenter}`}
                        >
                          <FormattedMessage
                            {...WaterBalanceResultBodyMessages.frostStress}
                          />
                          <div className={styled["help-icon"]}>
                            <Icon
                              name={ICON_NAMES_ENUM.help_circle}
                              className={styled.file__icon}
                            />
                          </div>
                        </div>
                      </div>
                    </TooltipMUI>
                  </th>

                  <th rowSpan={2}>
                    <FormattedMessage
                      {...WaterBalanceResultBodyMessages.days}
                    />
                  </th>
                </tr>
                <tr className={styled.specialCaseTable}>
                  <th>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.controlZoneSubTitle}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.agriPVZoneSubTitle}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.controlZoneSubTitle}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.agriPVZoneSubTitle}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableValues.map((thermal_period: any, index: number) => (
                  <tr key={thermal_period.simulation_result_id}>
                    <td>
                      <span>{thermal_period.period.name}</span>
                    </td>
                    <td>{thermal_period.control_zone_twenty_five_degres}</td>
                    <td>{thermal_period.agripv_twenty_five_degres}</td>
                    <td>{thermal_period.control_zone_zero_degres}</td>
                    <td>{thermal_period.agripv_zero_degres}</td>
                    <td>{thermal_period.total_days}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ThermalStress;
